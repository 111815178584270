export const Bolt = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		stroke-width='1.5'
		stroke='currentColor'
		class='size-6'
	>
		<path
			stroke-linecap='round'
			stroke-linejoin='round'
			d='m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z'
		/>
	</svg>
);

export const Film = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		stroke-width='1.5'
		stroke='currentColor'
		class='size-6'
	>
		<path
			stroke-linecap='round'
			stroke-linejoin='round'
			d='M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0 1 18 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0 1 18 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 0 1 6 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5'
		/>
	</svg>
);

export const MapPin = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
		stroke-width='1.5'
		stroke='currentColor'
		class='size-6'
	>
		<path stroke-linecap='round' stroke-linejoin='round' d='M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z' />
		<path
			stroke-linecap='round'
			stroke-linejoin='round'
			d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z'
		/>
	</svg>
);

export const ResultIcon1 = () => (
	<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M23.89 12c0-6.627-5.324-12-11.89-12S.109 5.373.109 12 5.433 24 12 24c2.014 0 3.91-.508 5.573-1.4.62.354 1.338.558 2.105.558 2.326 0 4.212-1.865 4.212-4.165 0-.946-.319-1.818-.857-2.517.552-1.383.857-2.894.857-4.476zm-21.402.005c0-5.448 4.269-9.864 9.535-9.864s9.535 4.416 9.535 9.864c0 1.07-.166 2.099-.471 3.063a4.23 4.23 0 0 0-1.408-.239c-2.326 0-4.212 1.865-4.212 4.165 0 .72.185 1.397.51 1.988a9.21 9.21 0 0 1-3.953.888c-5.267-.001-9.536-4.418-9.536-9.865zm17.191 9.864c-1.514.021-2.84-1.267-2.819-2.788 0-1.54 1.262-2.788 2.819-2.788 1.507-.025 2.843 1.27 2.819 2.788 0 1.54-1.263 2.788-2.819 2.788z'
			stroke='#FFFFFF'
			stroke-width='1'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);

export const ResultIcon2 = () => (
	<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M18.364 5.636C17.8001 5.07212 17.0676 4.77963 16.3287 4.75855C15.5976 4.73769 15.2321 4.72726 15.147 4.70581C14.9649 4.65988 15.0937 4.71324 14.9325 4.61693C14.8571 4.57194 14.5913 4.32084 14.0595 3.81864C13.5222 3.31114 12.7974 3 12 3C11.2026 3 10.4778 3.31113 9.94047 3.81863C9.40876 4.32082 9.14287 4.57195 9.06753 4.61694C8.90626 4.71325 9.0351 4.65988 8.85296 4.70581C8.76788 4.72727 8.40232 4.73769 7.67121 4.75855C6.93238 4.77963 6.19986 5.07211 5.63597 5.636C5.07207 6.1999 4.77959 6.93243 4.75852 7.67126C4.73766 8.40235 4.72724 8.7679 4.70578 8.85299C4.65985 9.03512 4.71322 8.90628 4.61691 9.06755C4.57192 9.14289 4.32082 9.40876 3.81862 9.94048C3.31113 10.4778 3 11.2026 3 12C3 12.7974 3.31113 13.5222 3.81862 14.0595C4.32082 14.5912 4.57192 14.8571 4.61691 14.9324C4.71322 15.0937 4.65985 14.9649 4.70578 15.147C4.72724 15.2321 4.73766 15.5976 4.75852 16.3287C4.77959 17.0676 5.07207 17.8001 5.63597 18.364C6.19986 18.9279 6.93238 19.2204 7.67121 19.2414C8.40225 19.2623 8.76788 19.2727 8.85296 19.2942C9.0351 19.3401 8.90626 19.2868 9.06753 19.3831C9.14287 19.4281 9.40874 19.6792 9.94047 20.1814C10.4778 20.6889 11.2026 21 12 21C12.7974 21 13.5222 20.6889 14.0595 20.1814C14.5912 19.6792 14.8571 19.4281 14.9325 19.3831C15.0937 19.2868 14.9649 19.3401 15.147 19.2942C15.2321 19.2727 15.5976 19.2623 16.3287 19.2414C17.0676 19.2204 17.8001 18.9279 18.364 18.364C18.9278 17.8001 19.2203 17.0676 19.2414 16.3288C19.2623 15.5977 19.2727 15.2321 19.2942 15.147C19.3401 14.9649 19.2867 15.0937 19.383 14.9325C19.428 14.8571 19.6792 14.5912 20.1814 14.0595C20.6889 13.5222 21 12.7974 21 12C21 11.2026 20.6889 10.4778 20.1814 9.94045C19.6792 9.40876 19.428 9.14286 19.383 9.06752C19.2867 8.90625 19.3401 9.0351 19.2942 8.85296C19.2727 8.76787 19.2623 8.40232 19.2414 7.67121C19.2203 6.9324 18.9278 6.19989 18.364 5.636Z'
			stroke='#FFFFFF'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);

export const ResultIcon3 = () => (
	<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M7 12H17M8 8.5C8 8.5 9 9 10 9C11.5 9 12.5 8 14 8C15 8 16 8.5 16 8.5M8 15.5C8 15.5 9 16 10 16C11.5 16 12.5 15 14 15C15 15 16 15.5 16 15.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z'
			stroke='#FFFFFF'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);

export const ResultIcon4 = () => (
	<svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M6.5 12.25C6.5 9.07436 9.07436 6.5 12.25 6.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z'
			stroke='#FFFFFF'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);
